@import '../node_modules/@salesforce-ux/c360-styling-hooks/dist/kx.custom-props.css';

:root {
  --nav-integration-c-header-height-desktop: 96px;
  --c360-nav-c-overlay-z-index: 400;
  --c360-nav-c-padding-top: 138px;
}

section.panel-nav {
  position: fixed;
  top: var(--nav-integration-c-header-height-desktop);
  transition: none;

  /** left panel **/
  .panel-a {
    background: var(--c360-g-color-palette-blue-90);
    width: 568px;
  }

  /** right panel **/
  .panel-b {
    background: var(--c360-g-color-palette-green-80);
    width: 16px;
    transition: none;

    /** animation-specific class **/
    &.animation-panel-transition {
      transition: all var(--c360-g-kx-duration-long) var(--c360-g-kx-ease-in-out);
    }
  }

  /** animation-specific class **/
  &.animation-panel-transition {
    transition: all var(--c360-g-kx-duration-long) var(--c360-g-kx-ease-in-out);
  }

  /** animation-specific class **/
  &.active {
    transform: translateX(0);
    visibility: visible;
    display: flex;

    .panel-b {
      width: 420px;
    }
  }

  .panel-nav__item {
    position: relative;
    // default animated styles
    opacity: 0;

    /** animation-specific class **/
    &.waterfall-offset.waterfall-show {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /** animation-specific class **/
    &.waterfall-offset {
      top: -0.75em;
    }

    /** animation-specific class **/
    &.waterfall-show {
      transition:
        opacity var(--c360-g-kx-duration-long) var(--c360-g-kx-ease-in-out) 0.15s,
        top var(--c360-g-kx-duration-x-long) var(--c360-g-kx-ease-in-out) 0.15s;
      top: 0;
      opacity: 1;
    }
  }
}