:root {
  --nav-integration-c-header-height-desktop: 96px;
  --c360-nav-c-overlay-z-index: 400;
  --c360-nav-c-padding-top: 138px;
}

section.panel-nav {
  top: var(--nav-integration-c-header-height-desktop);
  transition: none;
  position: fixed;
}

section.panel-nav .panel-a {
  background: var(--c360-g-color-palette-blue-90);
  width: 568px;
}

section.panel-nav .panel-b {
  background: var(--c360-g-color-palette-green-80);
  width: 16px;
  transition: none;
}

section.panel-nav .panel-b.animation-panel-transition, section.panel-nav.animation-panel-transition {
  transition: all var(--c360-g-kx-duration-long) var(--c360-g-kx-ease-in-out);
}

section.panel-nav.active {
  visibility: visible;
  display: flex;
  transform: translateX(0);
}

section.panel-nav.active .panel-b {
  width: 420px;
}

section.panel-nav .panel-nav__item {
  opacity: 0;
  position: relative;
}

section.panel-nav .panel-nav__item.waterfall-offset.waterfall-show {
  flex-direction: row;
  align-items: center;
  display: flex;
}

section.panel-nav .panel-nav__item.waterfall-offset {
  top: -.75em;
}

section.panel-nav .panel-nav__item.waterfall-show {
  transition: opacity var(--c360-g-kx-duration-long) var(--c360-g-kx-ease-in-out) .15s, top var(--c360-g-kx-duration-x-long) var(--c360-g-kx-ease-in-out) .15s;
  opacity: 1;
  top: 0;
}

/*# sourceMappingURL=index.f533c1d0.css.map */
